<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="code" placeholder="请输入入库单号" />
            <a-input v-model:value="itemName" placeholder="请输入存货名称" />
            <a-input v-model:value="itemCode" placeholder="请输入存货编码" />
            <span>入库日期：</span>
            <a-range-picker v-model:value="date" valueFormat="YYYY-MM-DD" />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
    emits: ["search"],
    setup(props, { emit }) {
        const state = reactive({
            code: undefined,
            itemName: undefined,
            itemCode: undefined,
            date: [],
        });

        const reset = () => {
            state.code = undefined;
            state.itemName = undefined;
            state.itemCode = undefined;
            state.date = [];
            search();
        };

        const search = () => {
            let { code, itemName, itemCode, date } = state;
            let params = {
                code: code?.trim(),
                itemName: itemName?.trim(),
                itemCode: itemCode?.trim(),
            };
            if (date.length) {
                let [inDateFrom, inDateTo] = date;
                params = {
                    ...params,
                    inDateFrom,
                    inDateTo,
                };
            }
            emit("search", params);
        };

        search();

        return {
            ...toRefs(state),
            search,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>
