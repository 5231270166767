<template>
    <div class="container">
        <Detail v-model:detailId="detailId" />
        <BarcodePrintSearch v-model:barcodePrintSearchVisible="barcodePrintSearchVisible" />
        <FilterBlock @search="search" />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1200 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="detailId = record.id">详情</a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-space direction="horizontal" :size="8">
                            <a-button
                                type="primary"
                                @click="() => (barcodePrintSearchVisible = true)"
                            >
                                条码打印列表
                            </a-button>
                            <!-- <a-button type="primary" @click="() => openUpDateModal('')">
                                导入
                            </a-button> -->
                        </a-space>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import Detail from "./detail";
import BarcodePrintSearch from "./barcodePrintSearch";
import { apiMaterialList } from "@/api";
export default defineComponent({
    components: {
        FilterBlock,
        Detail,
        BarcodePrintSearch,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "入库单号",
                dataIndex: "code",
            },
            {
                title: "存货名称",
                dataIndex: "itemName",
            },
            {
                title: "存货编码",
                dataIndex: "itemCode",
            },
            {
                title: "规格型号",
                dataIndex: "itemSpec",
            },
            {
                title: "主计量单位",
                dataIndex: "primaryUom",
            },
            {
                title: "数量",
                dataIndex: "count",
            },
            {
                title: "批次号",
                dataIndex: "batchNo",
            },
            {
                title: "供应商",
                dataIndex: "supplier",
            },
            {
                title: "入库日期",
                dataIndex: "inDate",
            },
            {
                title: "记账人",
                dataIndex: "bookkeeper",
            },
            {
                title: "制单人",
                dataIndex: "createBy",
            },
            {
                title: "审核人",
                dataIndex: "reviewedBy",
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 80,
            },
        ];

        const state = reactive({
            detailId: null,
            barcodePrintSearchVisible: false,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiMaterialList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    categoryCode: state.categoryCode,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                const { totalRecord, recordList } = res?.data;
                state.pagination.total = totalRecord;
                state.data = recordList;
            }
        };

        return {
            columns,
            ...toRefs(state),
            paginationChange,
            getTabelData,
            search,
        };
    },
});
</script>

<style lang="less" scoped>
.container {
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}
</style>
