<template>
    <BarcodeList v-model:params="params" v-model:exportList="exportList" typeName="字典" />
    <a-modal
        v-model:visible="visible"
        title="条码打印列表-查询"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="800px"
    >
        <a-form
            :model="formData"
            ref="formRef"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="getMaterialExportList"
        >
            <a-form-item label="入库日期" name="name">
                <a-range-picker v-model:value="formData.date" valueFormat="YYYY-MM-DD" />
            </a-form-item>

            <a-form-item label="存货编码" name="itemCode">
                <a-form-item class="contant-item">
                    <a-input v-model:value="formData.itemCodeFrom" :maxlength="100" showCount />
                    <span class="to-text">至</span>
                    <a-input v-model:value="formData.itemCodeTo" :maxlength="100" showCount />
                </a-form-item>
            </a-form-item>

            <a-form-item label="供应商" name="supplierCode">
                <a-form-item class="contant-item">
                    <a-input v-model:value="formData.supplierCodeFrom" :maxlength="100" showCount />
                    <span class="to-text">至</span>
                    <a-input v-model:value="formData.supplierCodeTo" :maxlength="100" showCount />
                </a-form-item>
            </a-form-item>

            <a-form-item label="批号" name="batchNo">
                <a-form-item class="contant-item">
                    <a-input v-model:value="formData.batchNoFrom" :maxlength="100" showCount />
                    <span class="to-text">至</span>
                    <a-input v-model:value="formData.batchNoTo" :maxlength="100" showCount />
                </a-form-item>
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 18, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit" :loading="loading">提交查询</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import BarcodeList from "./barcodeList";
import { showMessage } from "@/utils/common";
import { apiMaterialExportList } from "@/api";

export default defineComponent({
    props: ["barcodePrintSearchVisible"],
    components: {
        BarcodeList,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            barcodeListVisible: false,
            formRef: null,
            loading: false,
            formData: {
                date: [],
                itemCodeFrom: "",
                itemCodeTo: "",
                supplierCodeFrom: "",
                supplierCodeTo: "",
                batchNoFrom: "",
                batchNoTo: "",
            },
            params: null,
            exportList: [],
        });

        const handleCancel = () => {
            state.formRef.resetFields();
            state.formData.id = "";
            state.visible = false;
            emit("update:barcodePrintSearchVisible", null);
        };

        const getMaterialExportList = async () => {
            state.loading = true;
            let {
                date,
                itemCodeFrom,
                itemCodeTo,
                supplierCodeFrom,
                supplierCodeTo,
                batchNoFrom,
                batchNoTo,
            } = state.formData;
            state.params = {
                itemCodeFrom: itemCodeFrom?.trim(),
                itemCodeTo: itemCodeTo?.trim(),
                supplierCodeFrom: supplierCodeFrom?.trim(),
                supplierCodeTo: supplierCodeTo?.trim(),
                batchNoFrom: batchNoFrom?.trim(),
                batchNoTo: batchNoTo?.trim(),
            };
            if (date && date.length) {
                let [inDateFrom, inDateTo] = date;
                state.params = {
                    ...state.params,
                    inDateFrom,
                    inDateTo,
                    itemCodeFrom,
                    itemCodeTo,
                    supplierCodeFrom,
                    supplierCodeTo,
                    batchNoFrom,
                    batchNoTo,
                };
            }
            let res = await apiMaterialExportList(state.params);
            state.loading = false;
            if (res.status === "SUCCESS") {
                if (res?.data?.length == 0) {
                    showMessage("info", "暂无数据");
                }
                state.exportList = res?.data?.map((item, index) => ({
                    ...item,
                    index: index + 1,
                }));
            }
        };

        watch(
            () => props.barcodePrintSearchVisible,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            getMaterialExportList,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.contant-item) {
    .ant-form-item-control-input-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .to-text {
            margin: 0 10px;
        }
    }
}
</style>
