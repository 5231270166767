<template>
    <a-modal
        v-model:visible="visible"
        title="条码打印列表"
        @cancel="handleCancel"
        @ok="handleExport"
        :destroyOnClose="true"
        okText="导出"
        width="1200px"
    >
        <a-table
            :columns="columns"
            :data-source="exportList"
            bordered
            size="small"
            :pagination="pagination"
            :scroll="{ x: 1200 }"
            :destroyOnClose="true"
            :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            class="ant-table-striped"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ record.index }}
                </template>
            </template>
        </a-table>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { jsonToUrlParams } from "@/utils/common";
import { baseURL } from "@/utils/config";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "物料批次条码",
        dataIndex: "itemBarcode",
    },
    {
        title: "存货编码",
        dataIndex: "itemCode",
    },
    {
        title: "存货名称",
        dataIndex: "itemName",
    },
    {
        title: "规格型号",
        dataIndex: "itemSpec",
    },
    {
        title: "入库日期",
        dataIndex: "inDate",
    },
    {
        title: "入库数量",
        dataIndex: "count",
    },
    {
        title: "单位",
        dataIndex: "primaryUom",
    },
];

export default defineComponent({
    props: ["params", "exportList"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            pagination: {
                showSizeChanger: false,
            },
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:exportList", []);
        };

        const handleExport = () => {
            let url = `${baseURL}/web/erp-wv/export-list`;
            url = url + jsonToUrlParams(props.params);
            window.open(url);
        };

        watch(
            () => props.exportList?.length,
            newVal => {
                if (newVal) {
                    state.visible = true;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            columns,
            handleExport,
        };
    },
});
</script>

<style lang="less" scoped>
.contant-item {
    display: flex;
    justify-content: space-between;
}
</style>
